import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/star.svg'
import _imports_1 from '@/assets/icons/star-fill.svg'
import _imports_2 from '@/assets/icons/zan.svg'
import _imports_3 from '@/assets/icons/zan-fill.svg'
import _imports_4 from '@/assets/icons/comment.svg'
import _imports_5 from '@/assets/icons/Ellipse.svg'


const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "rectangle" }
const _hoisted_4 = { class: "creator-info" }
const _hoisted_5 = { class: "creator-name" }
const _hoisted_6 = { class: "avatar-container" }
const _hoisted_7 = { class: "ideaheader-container" }
const _hoisted_8 = { class: "titlebar-container" }
const _hoisted_9 = { class: "tool-container" }
const _hoisted_10 = { src: _imports_0 }
const _hoisted_11 = { src: _imports_1 }
const _hoisted_12 = { class: "icon-text" }
const _hoisted_13 = { src: _imports_2 }
const _hoisted_14 = { src: _imports_3 }
const _hoisted_15 = { class: "icon-text" }
const _hoisted_16 = {
  class: "icon-button",
  style: {"cursor":"auto"}
}
const _hoisted_17 = { class: "icon-text" }
const _hoisted_18 = { src: _imports_5 }
const _hoisted_19 = { src: _imports_5 }
const _hoisted_20 = {
  key: 0,
  class: "content"
}
const _hoisted_21 = { class: "content-left" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "content-right" }
const _hoisted_26 = { class: "label-text" }
const _hoisted_27 = {
  key: 0,
  class: "label-text",
  style: {"margin-top":"1.5rem"}
}
const _hoisted_28 = {
  class: "label-text",
  style: {"margin-top":"1.5rem"}
}
const _hoisted_29 = ["fill"]
const _hoisted_30 = {
  class: "label-text",
  style: {"margin-top":"2rem"}
}
const _hoisted_31 = { class: "submittime-text" }
const _hoisted_32 = {
  class: "label-text",
  style: {"margin-top":"2rem","display":"flex"}
}
const _hoisted_33 = { class: "attachments-num" }
const _hoisted_34 = {
  key: 1,
  class: "content"
}
const _hoisted_35 = { class: "content-left" }
const _hoisted_36 = { class: "userinfo-bar" }
const _hoisted_37 = { class: "userinfo" }
const _hoisted_38 = { style: {"margin-left":"1rem"} }
const _hoisted_39 = { class: "username" }
const _hoisted_40 = { class: "createtime" }
const _hoisted_41 = { style: {"width":"100%","padding-top":"0.5rem","padding-bottom":"0.5rem"} }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "comment-content" }
const _hoisted_44 = { class: "root-comment" }
const _hoisted_45 = { style: {"display":"flex","gap":"1rem"} }
const _hoisted_46 = ["onClick"]
const _hoisted_47 = {
  key: 0,
  src: _imports_3
}
const _hoisted_48 = {
  key: 1,
  src: _imports_2
}
const _hoisted_49 = { class: "action-num" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { class: "child-comment" }
const _hoisted_52 = {
  class: "userinfo-bar",
  style: {"padding":"0"}
}
const _hoisted_53 = { class: "userinfo" }
const _hoisted_54 = { style: {"margin-left":"1rem"} }
const _hoisted_55 = { class: "username" }
const _hoisted_56 = { class: "createtime" }
const _hoisted_57 = { class: "createtime" }
const _hoisted_58 = { style: {"width":"100%","padding-top":"0.5rem","padding-bottom":"0.5rem"} }
const _hoisted_59 = ["onClick"]
const _hoisted_60 = { style: {"margin-left":"3.5rem","margin-top":"1rem"} }
const _hoisted_61 = { style: {"display":"flex","gap":"1rem","margin-left":"3.5rem","margin-top":"1rem"} }
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { src: _imports_3 }
const _hoisted_64 = { src: _imports_2 }
const _hoisted_65 = { class: "action-num" }
const _hoisted_66 = ["onClick"]
const _hoisted_67 = {
  key: 0,
  style: {"display":"flex","padding-top":"1rem","padding-bottom":"1rem"}
}
const _hoisted_68 = { style: {"width":"100%"} }
const _hoisted_69 = { style: {"display":"flex","margin-left":"1rem"} }
const _hoisted_70 = { class: "content-right" }
const _hoisted_71 = { class: "right-title" }
const _hoisted_72 = { class: "user-avatar-group" }
const _hoisted_73 = { class: "num-round" }
const _hoisted_74 = { class: "right-title" }
const _hoisted_75 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_MoreFilled = _resolveComponent("MoreFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CaretRight = _resolveComponent("CaretRight")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar, { index: '/browse' })
    ]),
    _createElementVNode("section", {
      class: "banner-container",
      style: _normalizeStyle({
      backgroundImage:
        'url(' +
        ($setup.IdeaItem.Idea.BannerImgUrl ? $setup.IdeaItem.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache() : require('@/assets/images/login/TOP.png')) +
        ')',
    })
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString($setup.IdeaItem.User.UserName), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_avatar, {
              src: 'data:image/jpeg;base64,' + $setup.IdeaItem.User.Avatar
            }, null, 8, ["src"])
          ])
        ])
      ])
    ], 4),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_el_page_header, {
          onBack: $setup.goBack,
          icon: "ArrowLeft",
          title: $setup.IdeaItem.Idea.Title
        }, null, 8, ["onBack", "title"]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleCollectClick($setup.IdeaItem.Idea.IdeaId))),
            class: "icon-button"
          }, [
            _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
              [_vShow, !$setup.IdeaItem.IsCollect]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_11, null, 512), [
              [_vShow, $setup.IdeaItem.IsCollect]
            ]),
            _createElementVNode("span", _hoisted_12, _toDisplayString($setup.IdeaItem.Idea.CollectCount), 1)
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleLikeClick($setup.IdeaItem.Idea.IdeaId))),
            class: "icon-button"
          }, [
            _withDirectives(_createElementVNode("img", _hoisted_13, null, 512), [
              [_vShow, !$setup.IdeaItem.IsLike]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_14, null, 512), [
              [_vShow, $setup.IdeaItem.IsLike]
            ]),
            _createElementVNode("span", _hoisted_15, _toDisplayString($setup.IdeaItem.Idea.LikeCount), 1)
          ]),
          _createElementVNode("button", _hoisted_16, [
            _cache[8] || (_cache[8] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
            _createElementVNode("span", _hoisted_17, _toDisplayString($setup.IdeaItem.Idea.CommentCount), 1)
          ]),
          (!$setup.IdeaItem.IsFollow)
            ? (_openBlock(), _createBlock(_component_el_popover, {
                key: 0,
                placement: "bottom-start",
                trigger: "hover",
                width: 200,
                content: _ctx.$t('followIdeaMsg')
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: "follow-btn",
                    onClick: $setup.followIdea
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("followIdea")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["content"]))
            : _createCommentVNode("", true),
          ($setup.IdeaItem.IsFollow)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                class: "follow-btn",
                onClick: $setup.unfollowIdea
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("unfollowIdea")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_el_menu, {
        "default-active": "1",
        mode: "horizontal",
        ellipsis: false,
        class: "custom-menu",
        onSelect: $setup.handleTabChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: "1",
            class: "custom-menuitem"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("idea")) + " ", 1),
              _withDirectives(_createElementVNode("img", _hoisted_18, null, 512), [
                [_vShow, false]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, {
            index: "2",
            class: "custom-menuitem"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("comments")) + " ", 1),
              _withDirectives(_createElementVNode("img", _hoisted_19, null, 512), [
                [_vShow, true]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSelect"])
    ]),
    ($setup.currentTab == 1)
      ? (_openBlock(), _createElementBlock("section", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              innerHTML: $setup.IdeaItem.Idea.Description
            }, null, 8, _hoisted_22),
            ($setup.IdeaItem.Idea.BannerImgUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("img", {
                    src: $setup.IdeaItem.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    width: "100%"
                  }, null, 8, _hoisted_24)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("category")), 1),
            _createVNode(_component_el_select, {
              modelValue: $setup.IdeaItem.Category.CategoryName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.IdeaItem.Category.CategoryName) = $event)),
              disabled: "",
              placeholder: "Select",
              style: {"width":"240px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_el_option, {
                  key: $setup.IdeaItem.Category.CategoryName,
                  label: $setup.IdeaItem.Category.CategoryName,
                  value: $setup.IdeaItem.Category.CategoryName
                }, null, 8, ["label", "value"]))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            ($setup.IdeaItem.Tag.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.$t("tag")), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.IdeaItem.Tag, (tag, index) => {
              return (_openBlock(), _createBlock(_component_el_tag, {
                key: index,
                effect: "plain",
                color: "#f36633",
                size: "large",
                round: "true",
                style: {"--el-tag-text-color":"white","--el-tag-border-color":"#f36633","--el-tag-hover-color":"#f36633","margin-right":"0.3rem"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tag.TagName), 1)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("status")), 1),
            (_openBlock(), _createElementBlock("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "0.5rem",
              height: "0.5rem",
              viewBox: "0 0 8 9",
              fill: $setup.getStatusColor($setup.IdeaItem.Idea.Status)
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("circle", {
                cx: "4",
                cy: "4.5",
                r: "4"
              }, null, -1)
            ]), 8, _hoisted_29)),
            _createElementVNode("span", {
              class: "status-text",
              style: _normalizeStyle(`color: ${$setup.getStatusColor($setup.IdeaItem.Idea.Status)}`)
            }, "  " + _toDisplayString($setup.getStatusText($setup.IdeaItem.Idea.Status)), 5),
            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("submitted")), 1),
            _createElementVNode("span", _hoisted_31, _toDisplayString($setup.formatTimeAgo($setup.IdeaItem.Idea.CreatedOn)), 1),
            _createElementVNode("div", _hoisted_32, [
              _createTextVNode(_toDisplayString(_ctx.$t("attachments")) + " ", 1),
              _createElementVNode("div", _hoisted_33, _toDisplayString($setup.IdeaItem.AttachmentList?.length || 0), 1)
            ]),
            _createVNode(_component_el_upload, {
              "file-list": $setup.AttFileList,
              "onUpdate:fileList": _cache[3] || (_cache[3] = ($event: any) => (($setup.AttFileList) = $event)),
              disabled: "",
              "on-preview": $setup.handleAttPreview,
              style: {"--el-color-primary":"#f36633","margin-top":"-1.5rem","margin-left":"-0.5rem"}
            }, null, 8, ["file-list", "on-preview"])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.currentTab == 2)
      ? (_openBlock(), _createElementBlock("section", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commentData, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "comment-item"
              }, [
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createVNode(_component_el_avatar, {
                      src: 'data:image/jpeg;base64,' + item.User.Avatar,
                      size: 40
                    }, null, 8, ["src"]),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, _toDisplayString(item.User.UserName), 1),
                      _createElementVNode("div", _hoisted_40, _toDisplayString($setup.formatTimeAgo(item.Comment.CreatedOn)), 1)
                    ])
                  ]),
                  (
              $setup.getUserInfo()?.graphUserInfo!.UserId == item.Comment.CreatedBy
            )
                    ? (_openBlock(), _createBlock(_component_el_popover, {
                        key: 0,
                        placement: "bottom-end",
                        trigger: "hover",
                        "popper-class": "custom-popper"
                      }, {
                        reference: _withCtx(() => [
                          _createVNode(_component_el_icon, { style: {"color":"#484851","cursor":"pointer"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_MoreFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("button", {
                              class: "custom-btn",
                              onClick: ($event: any) => ($setup.DeleteComment(item.Comment.CommId))
                            }, _toDisplayString(_ctx.$t("delete")), 9, _hoisted_42)
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, _toDisplayString(item.Comment.Content), 1),
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", {
                      class: "action-bar",
                      onClick: ($event: any) => (
                item.IsLike
                  ? $setup.CommentLikeCancel(item.Comment.CommId)
                  : $setup.CommentLike(item.Comment.CommId)
                )
                    }, [
                      (item.IsLike)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_47))
                        : _createCommentVNode("", true),
                      (!item.IsLike)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_48))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_49, _toDisplayString(item.Comment.LikeCount), 1)
                    ], 8, _hoisted_46),
                    _createElementVNode("span", {
                      class: "reply-button",
                      onClick: ($event: any) => ($setup.handleReplyClick(item.Comment.CommId))
                    }, _toDisplayString(_ctx.$t("reply")), 9, _hoisted_50)
                  ])
                ]),
                _createElementVNode("div", _hoisted_51, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Reply, (reply, reply_index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "child-comment-content",
                      key: reply_index
                    }, [
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("div", _hoisted_53, [
                          _createVNode(_component_el_avatar, {
                            src: 'data:image/jpeg;base64,' + reply.User.Avatar,
                            size: 40
                          }, null, 8, ["src"]),
                          _createElementVNode("div", _hoisted_54, [
                            _createElementVNode("div", _hoisted_55, [
                              _createTextVNode(_toDisplayString(reply.User.UserName) + " ", 1),
                              _createElementVNode("span", _hoisted_56, [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_CaretRight)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _createTextVNode(" " + _toDisplayString(reply.PareCommUser.UserName), 1)
                            ]),
                            _createElementVNode("div", _hoisted_57, _toDisplayString($setup.formatTimeAgo(reply.Comment.CreatedOn)), 1)
                          ])
                        ]),
                        (
                  $setup.getUserInfo()?.graphUserInfo!.UserId ==
                  reply.Comment.CreatedBy
                )
                          ? (_openBlock(), _createBlock(_component_el_popover, {
                              key: 0,
                              placement: "bottom-end",
                              trigger: "click",
                              "popper-class": "custom-popper"
                            }, {
                              reference: _withCtx(() => [
                                _createVNode(_component_el_icon, { style: {"color":"#484851","cursor":"pointer"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_MoreFilled)
                                  ]),
                                  _: 1
                                })
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_58, [
                                  _createElementVNode("button", {
                                    class: "custom-btn",
                                    onClick: ($event: any) => ($setup.DeleteComment(reply.Comment.CommId))
                                  }, _toDisplayString(_ctx.$t("delete")), 9, _hoisted_59)
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_60, _toDisplayString(reply.Comment.Content), 1),
                      _createElementVNode("div", _hoisted_61, [
                        _createElementVNode("div", {
                          class: "action-bar",
                          onClick: ($event: any) => (
                  reply.IsLike
                    ? $setup.CommentLikeCancel(reply.Comment.CommId)
                    : $setup.CommentLike(reply.Comment.CommId)
                  )
                        }, [
                          _withDirectives(_createElementVNode("img", _hoisted_63, null, 512), [
                            [_vShow, reply.IsLike]
                          ]),
                          _withDirectives(_createElementVNode("img", _hoisted_64, null, 512), [
                            [_vShow, !reply.IsLike]
                          ]),
                          _createElementVNode("span", _hoisted_65, _toDisplayString(reply.Comment.LikeCount), 1)
                        ], 8, _hoisted_62),
                        _createElementVNode("span", {
                          class: "reply-button",
                          onClick: ($event: any) => (
                  $setup.handleReplyClick(item.Comment.CommId, reply.Comment.CommId)
                  )
                        }, _toDisplayString(_ctx.$t("reply")), 9, _hoisted_66)
                      ])
                    ]))
                  }), 128)),
                  (item.Reply && item.commentCard)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                        _createVNode(_component_el_avatar, {
                          src: 'data:image/jpeg;base64,' + $setup.getUserInfo()!.graphUserInfo!.avatar,
                          size: 40
                        }, null, 8, ["src"]),
                        _createElementVNode("div", _hoisted_68, [
                          _createVNode(_component_el_input, {
                            modelValue: $setup.commentText,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.commentText) = $event)),
                            autosize: { minRows: 3 },
                            type: "textarea",
                            placeholder: "Please input",
                            style: {"margin-left":"1rem","--el-color-primary":"#f36633"}
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_69, [
                            _createVNode(_component_el_button, {
                              class: "submit-btn",
                              onClick: ($event: any) => (
                    $setup.handleChildCommentSubmit(
                      $setup.commentData[0].Comment.IdeaId,
                      item.Reply[0]
                        ? item.Reply[0].Comment.RootCommId
                        : item.Comment.CommId,
                      item.Comment.Content
                    )
                    )
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("submitLower")), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_el_button, {
                              class: "cancel-btn",
                              color: "white\n      ",
                              onClick: $setup.handleCancelClick
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_70, [
            _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("users")), 1),
            _createElementVNode("div", _hoisted_72, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.avatarArray.slice(0, 5), (avatar, index) => {
                return (_openBlock(), _createBlock(_component_el_avatar, {
                  key: index,
                  src: 'data:image/jpeg;base64,' + avatar
                }, null, 8, ["src"]))
              }), 128)),
              _createElementVNode("div", _hoisted_73, _toDisplayString($setup.avatarArray.length), 1)
            ]),
            _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.$t("yourSuggestions")), 1),
            _createVNode(_component_el_input, {
              modelValue: $setup.suggestions,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.suggestions) = $event)),
              rows: 3,
              type: "textarea",
              placeholder: _ctx.$t('yourSuggestionsMsg'),
              style: {"margin-top":"0.5rem","--el-color-primary":"#f36633"}
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_el_button, {
              class: "submit-btn",
              disabled: !$setup.suggestions,
              onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.handleSuggestionsSubmit($setup.IdeaItem.Idea.IdeaId)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("submitLower")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_el_button, {
              class: "cancel-btn",
              onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.suggestions = ''))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("clear")), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_75, _toDisplayString(_ctx.$t("aboutGSKMsg")), 1)
  ]))
}