<template>
  <div class="updatescard-container" @click="navToDetail(updatesItems.Update.IdeaId)">
    <el-avatar :src="'data:image/jpeg;base64,' + updatesItems.User.Avatar" :size="24"
      style="margin-top: 0.5rem"></el-avatar>
    <section v-if="updatesItems.Update.Type == 0 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">submitted a new</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 0 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">提交了一个新的</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 0 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">が新しいアイデアを提出しました</span>
        <span class="text-idea">アイデア</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 1 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">评论了</span>
        <span class="text-name">你</span>
        <span class="text-action">关注的</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 1 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">commented on your followed</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 1 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">がフォローしたアイデアにコメントしました</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 2 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">commented on your published</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 2 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">评论了</span>
        <span class="text-name">你</span>
        <span class="text-action">发布的</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 2 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">があなたの公開したアイデアにコメントしました</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
      </div>
      <div class="content-bar">
        <el-icon>
          <ChatDotRound />
        </el-icon>
        <span>{{ updatesItems.Update.Content }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 3 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">responded to your</span>
        <span class="text-idea">Comment</span>
        <span class="text-title"><span class="grey-text">"</span>{{ updatesItems.Update.Content.split(",")[0]
          }}<span class="grey-text">"</span></span>
        <span class="text-action">on</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">with</span>
        <div class="content-bar">
          <el-icon>
            <ChatDotRound />
          </el-icon>
          <span>{{ updatesItems.Update.Content.split(",")[1] }}</span>
        </div>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 3 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">回复了</span>
        <span class="text-name">你</span>
        <span class="text-action">在</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">的</span>
        <span class="text-idea">评论</span>
        <span class="text-title"><span class="grey-text">"</span>{{ updatesItems.Update.Content.split(",")[0]
          }}<span class="grey-text">"</span></span>
        <div class="content-bar">
          <el-icon>
            <ChatDotRound />
          </el-icon>
          <span>{{ updatesItems.Update.Content.split(",")[1] }}</span>
        </div>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 3 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-name">{{ updatesItems.User.UserName }}</span>
        <span class="text-action">があなたのコメントに返信しました</span>
        <span class="text-title"><span class="grey-text">"</span>{{ updatesItems.Update.Content.split(",")[0]
          }}<span class="grey-text">"</span></span>
        <span class="text-action">アイデアについて:</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <div class="content-bar">
          <el-icon>
            <ChatDotRound />
          </el-icon>
          <span>{{ updatesItems.Update.Content.split(",")[1] }}</span>
        </div>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 4 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-action">The Status of</span>
        <span class="text-name">Your</span>
        <span class="text-action">followed</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">has been changed from</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">to</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 4 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">你</span>
        <span class="text-action">关注的</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">的状态已经从</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">变为</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 4 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-action">フォローしているアイデアのステータス</span>
        <span class="text-name">「{{ updatesItems.Update.IdeaTitle.length > 10
          ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
          : updatesItems.Update.IdeaTitle }}」</span>
        <span class="text-action">が</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">から</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
        <span class="text-action">に変更されました</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>

    <section v-if="updatesItems.Update.Type == 5 && currentLanguage == 'en'">
      <div class="infoRow">
        <span class="text-action">The Status of</span>
        <span class="text-name">Your</span>
        <span class="text-idea">Idea</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">has been changed from</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">to</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 5 && currentLanguage == 'zh'">
      <div class="infoRow">
        <span class="text-name">你</span>
        <span class="text-action">的</span>
        <span class="text-idea">想法</span>
        <span class="text-title"><span class="grey-text">"</span>{{
          updatesItems.Update.IdeaTitle.length > 10
            ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : updatesItems.Update.IdeaTitle
        }}<span class="grey-text">"</span></span>
        <span class="text-action">的状态已经从</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">变为</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>
    <section v-if="updatesItems.Update.Type == 5 && currentLanguage == 'jp'">
      <div class="infoRow">
        <span class="text-action">あなたのアイデア</span>
        <span class="text-title">「{{ updatesItems.Update.IdeaTitle.length > 10
          ? updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
          : updatesItems.Update.IdeaTitle }}」</span>
        <span class="text-action">のステータスが</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[0])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[0])) }}</span>
        <span class="text-action">から</span>
        <span class="text-name" :style="{
          color: getStatusColor(parseInt(updatesItems.Update.Content[1])),
        }">{{ getStatusText(parseInt(updatesItems.Update.Content[1])) }}</span>
        <span class="text-action">に変更されました</span>
      </div>
      <div class="text-createdtime">
        {{ formatTimeAgo(updatesItems.Update.CreatedOn) }}
      </div>
    </section>

  </div>
</template>

<style scoped>
.updatescard-container {
  padding-top: 0.5rem;
  padding-bottom: 0.62rem;
  width: 100%;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  display: flex;
  box-sizing: border-box;
  gap: 1rem;
  cursor: pointer;
}

.infoRow {
  align-items: center;
  line-height: 2rem;
}

.infoRow>span {
  margin-right: 0.5rem;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
  flex-shrink: 0;
}

.text-name {
  color: #484851;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.text-action {
  color: #868693;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.text-idea {
  color: #868693;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  /* 150% */
}

.text-title {
  color: #376f7b;
  /* 14px-bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.grey-text {
  color: #868693;
}

.text-createdtime {
  color: #aeaeb7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.content-bar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #484851;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}
</style>

<script lang="ts">
import { defineComponent, PropType, inject, computed } from "vue";
import { ChatDotRound } from "@element-plus/icons-vue";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
interface User {
  Avatar: string;
  UserId: number;
  UserName: string;
}

interface UpdateItem {
  User: User;
  Update: Update;
}

interface Update {
  Content: string;
  CreatedBy: number;
  CreatedOn: string;
  IdeaId: number;
  UpdateId: number;
  UserId: number;
  IdeaTitle: string;
  Type: number;
}

export default defineComponent({
  name: "UpdatesCard",
  props: {
    updatesItems: {
      type: Object as PropType<UpdateItem>,
      required: true,
    },
  },
  setup() {
    /* eslint-disable */
    const formatTimeAgo = inject("formatTimeAgo") as (
      timestamp: string
    ) => string;
    const { locale } = useI18n();
    // const isEnglish = computed(() => locale.value === "en");
    const currentLanguage = computed(() => locale.value);
    const router = useRouter();
    const navToDetail = (IdeaId: number) => {
      router.push({
        path: "/ideas/" + IdeaId,
      });
    };
    return {
      currentLanguage,
      formatTimeAgo,
      getStatusText,
      getStatusColor,
      navToDetail,
      ChatDotRound,
    };
  },
});
</script>
