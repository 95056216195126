import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "infoRow" }
const _hoisted_3 = { class: "text-name" }
const _hoisted_4 = { class: "text-title" }
const _hoisted_5 = { class: "text-createdtime" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "infoRow" }
const _hoisted_8 = { class: "text-name" }
const _hoisted_9 = { class: "text-title" }
const _hoisted_10 = { class: "text-createdtime" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "infoRow" }
const _hoisted_13 = { class: "text-name" }
const _hoisted_14 = { class: "text-title" }
const _hoisted_15 = { class: "text-createdtime" }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = { class: "infoRow" }
const _hoisted_18 = { class: "text-name" }
const _hoisted_19 = { class: "text-title" }
const _hoisted_20 = { class: "content-bar" }
const _hoisted_21 = { class: "text-createdtime" }
const _hoisted_22 = { key: 4 }
const _hoisted_23 = { class: "infoRow" }
const _hoisted_24 = { class: "text-name" }
const _hoisted_25 = { class: "text-title" }
const _hoisted_26 = { class: "content-bar" }
const _hoisted_27 = { class: "text-createdtime" }
const _hoisted_28 = { key: 5 }
const _hoisted_29 = { class: "infoRow" }
const _hoisted_30 = { class: "text-name" }
const _hoisted_31 = { class: "text-title" }
const _hoisted_32 = { class: "content-bar" }
const _hoisted_33 = { class: "text-createdtime" }
const _hoisted_34 = { key: 6 }
const _hoisted_35 = { class: "infoRow" }
const _hoisted_36 = { class: "text-name" }
const _hoisted_37 = { class: "text-title" }
const _hoisted_38 = { class: "content-bar" }
const _hoisted_39 = { class: "text-createdtime" }
const _hoisted_40 = { key: 7 }
const _hoisted_41 = { class: "infoRow" }
const _hoisted_42 = { class: "text-name" }
const _hoisted_43 = { class: "text-title" }
const _hoisted_44 = { class: "content-bar" }
const _hoisted_45 = { class: "text-createdtime" }
const _hoisted_46 = { key: 8 }
const _hoisted_47 = { class: "infoRow" }
const _hoisted_48 = { class: "text-name" }
const _hoisted_49 = { class: "text-title" }
const _hoisted_50 = { class: "content-bar" }
const _hoisted_51 = { class: "text-createdtime" }
const _hoisted_52 = { key: 9 }
const _hoisted_53 = { class: "infoRow" }
const _hoisted_54 = { class: "text-name" }
const _hoisted_55 = { class: "text-title" }
const _hoisted_56 = { class: "text-title" }
const _hoisted_57 = { class: "content-bar" }
const _hoisted_58 = { class: "text-createdtime" }
const _hoisted_59 = { key: 10 }
const _hoisted_60 = { class: "infoRow" }
const _hoisted_61 = { class: "text-name" }
const _hoisted_62 = { class: "text-title" }
const _hoisted_63 = { class: "text-title" }
const _hoisted_64 = { class: "content-bar" }
const _hoisted_65 = { class: "text-createdtime" }
const _hoisted_66 = { key: 11 }
const _hoisted_67 = { class: "infoRow" }
const _hoisted_68 = { class: "text-name" }
const _hoisted_69 = { class: "text-title" }
const _hoisted_70 = { class: "text-title" }
const _hoisted_71 = { class: "content-bar" }
const _hoisted_72 = { class: "text-createdtime" }
const _hoisted_73 = { key: 12 }
const _hoisted_74 = { class: "infoRow" }
const _hoisted_75 = { class: "text-title" }
const _hoisted_76 = { class: "text-createdtime" }
const _hoisted_77 = { key: 13 }
const _hoisted_78 = { class: "infoRow" }
const _hoisted_79 = { class: "text-title" }
const _hoisted_80 = { class: "text-createdtime" }
const _hoisted_81 = { key: 14 }
const _hoisted_82 = { class: "infoRow" }
const _hoisted_83 = { class: "text-name" }
const _hoisted_84 = { class: "text-createdtime" }
const _hoisted_85 = { key: 15 }
const _hoisted_86 = { class: "infoRow" }
const _hoisted_87 = { class: "text-title" }
const _hoisted_88 = { class: "text-createdtime" }
const _hoisted_89 = { key: 16 }
const _hoisted_90 = { class: "infoRow" }
const _hoisted_91 = { class: "text-title" }
const _hoisted_92 = { class: "text-createdtime" }
const _hoisted_93 = { key: 17 }
const _hoisted_94 = { class: "infoRow" }
const _hoisted_95 = { class: "text-title" }
const _hoisted_96 = { class: "text-createdtime" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "updatescard-container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navToDetail(_ctx.updatesItems.Update.IdeaId)))
  }, [
    _createVNode(_component_el_avatar, {
      src: 'data:image/jpeg;base64,' + _ctx.updatesItems.User.Avatar,
      size: 24,
      style: {"margin-top":"0.5rem"}
    }, null, 8, ["src"]),
    (_ctx.updatesItems.Update.Type == 0 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-action" }, "submitted a new", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 0 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-action" }, "提交了一个新的", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 0 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-action" }, "が新しいアイデアを提出しました", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-idea" }, "アイデア", -1)),
            _createElementVNode("span", _hoisted_14, [
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 1 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-action" }, "评论了", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-name" }, "你", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-action" }, "关注的", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_19, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 1 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-action" }, "commented on your followed", -1)),
            _cache[22] || (_cache[22] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_25, [
              _cache[19] || (_cache[19] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 1 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[25] || (_cache[25] = _createElementVNode("span", { class: "text-action" }, "がフォローしたアイデアにコメントしました", -1)),
            _createElementVNode("span", _hoisted_31, [
              _cache[23] || (_cache[23] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[24] || (_cache[24] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 2 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[28] || (_cache[28] = _createElementVNode("span", { class: "text-action" }, "commented on your published", -1)),
            _cache[29] || (_cache[29] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_37, [
              _cache[26] || (_cache[26] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[27] || (_cache[27] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 2 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "text-action" }, "评论了", -1)),
            _cache[33] || (_cache[33] = _createElementVNode("span", { class: "text-name" }, "你", -1)),
            _cache[34] || (_cache[34] = _createElementVNode("span", { class: "text-action" }, "发布的", -1)),
            _cache[35] || (_cache[35] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_43, [
              _cache[30] || (_cache[30] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[31] || (_cache[31] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_44, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 2 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[38] || (_cache[38] = _createElementVNode("span", { class: "text-action" }, "があなたの公開したアイデアにコメントしました", -1)),
            _createElementVNode("span", _hoisted_49, [
              _cache[36] || (_cache[36] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[37] || (_cache[37] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_50, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content), 1)
          ]),
          _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 3 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_52, [
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[43] || (_cache[43] = _createElementVNode("span", { class: "text-action" }, "responded to your", -1)),
            _cache[44] || (_cache[44] = _createElementVNode("span", { class: "text-idea" }, "Comment", -1)),
            _createElementVNode("span", _hoisted_55, [
              _cache[39] || (_cache[39] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.Content.split(",")[0]), 1),
              _cache[40] || (_cache[40] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[45] || (_cache[45] = _createElementVNode("span", { class: "text-action" }, "on", -1)),
            _cache[46] || (_cache[46] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_56, [
              _cache[41] || (_cache[41] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[42] || (_cache[42] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[47] || (_cache[47] = _createElementVNode("span", { class: "text-action" }, "with", -1)),
            _createElementVNode("div", _hoisted_57, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatDotRound)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content.split(",")[1]), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 3 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_59, [
          _createElementVNode("div", _hoisted_60, [
            _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[52] || (_cache[52] = _createElementVNode("span", { class: "text-action" }, "回复了", -1)),
            _cache[53] || (_cache[53] = _createElementVNode("span", { class: "text-name" }, "你", -1)),
            _cache[54] || (_cache[54] = _createElementVNode("span", { class: "text-action" }, "在", -1)),
            _cache[55] || (_cache[55] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_62, [
              _cache[48] || (_cache[48] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[49] || (_cache[49] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[56] || (_cache[56] = _createElementVNode("span", { class: "text-action" }, "的", -1)),
            _cache[57] || (_cache[57] = _createElementVNode("span", { class: "text-idea" }, "评论", -1)),
            _createElementVNode("span", _hoisted_63, [
              _cache[50] || (_cache[50] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.Content.split(",")[0]), 1),
              _cache[51] || (_cache[51] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _createElementVNode("div", _hoisted_64, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatDotRound)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content.split(",")[1]), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 3 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.updatesItems.User.UserName), 1),
            _cache[62] || (_cache[62] = _createElementVNode("span", { class: "text-action" }, "があなたのコメントに返信しました", -1)),
            _createElementVNode("span", _hoisted_69, [
              _cache[58] || (_cache[58] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.Content.split(",")[0]), 1),
              _cache[59] || (_cache[59] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[63] || (_cache[63] = _createElementVNode("span", { class: "text-action" }, "アイデアについて:", -1)),
            _createElementVNode("span", _hoisted_70, [
              _cache[60] || (_cache[60] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[61] || (_cache[61] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _createElementVNode("div", _hoisted_71, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatDotRound)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.updatesItems.Update.Content.split(",")[1]), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 4 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_73, [
          _createElementVNode("div", _hoisted_74, [
            _cache[66] || (_cache[66] = _createElementVNode("span", { class: "text-action" }, "The Status of", -1)),
            _cache[67] || (_cache[67] = _createElementVNode("span", { class: "text-name" }, "Your", -1)),
            _cache[68] || (_cache[68] = _createElementVNode("span", { class: "text-action" }, "followed", -1)),
            _cache[69] || (_cache[69] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_75, [
              _cache[64] || (_cache[64] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[65] || (_cache[65] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[70] || (_cache[70] = _createElementVNode("span", { class: "text-action" }, "has been changed from", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[71] || (_cache[71] = _createElementVNode("span", { class: "text-action" }, "to", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5)
          ]),
          _createElementVNode("div", _hoisted_76, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 4 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_77, [
          _createElementVNode("div", _hoisted_78, [
            _cache[74] || (_cache[74] = _createElementVNode("span", { class: "text-name" }, "你", -1)),
            _cache[75] || (_cache[75] = _createElementVNode("span", { class: "text-action" }, "关注的", -1)),
            _cache[76] || (_cache[76] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_79, [
              _cache[72] || (_cache[72] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[73] || (_cache[73] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[77] || (_cache[77] = _createElementVNode("span", { class: "text-action" }, "的状态已经从", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[78] || (_cache[78] = _createElementVNode("span", { class: "text-action" }, "变为", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5)
          ]),
          _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 4 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_81, [
          _createElementVNode("div", _hoisted_82, [
            _cache[79] || (_cache[79] = _createElementVNode("span", { class: "text-action" }, "フォローしているアイデアのステータス", -1)),
            _createElementVNode("span", _hoisted_83, "「" + _toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
          ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
          : _ctx.updatesItems.Update.IdeaTitle) + "」", 1),
            _cache[80] || (_cache[80] = _createElementVNode("span", { class: "text-action" }, "が", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[81] || (_cache[81] = _createElementVNode("span", { class: "text-action" }, "から", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5),
            _cache[82] || (_cache[82] = _createElementVNode("span", { class: "text-action" }, "に変更されました", -1))
          ]),
          _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 5 && _ctx.currentLanguage == 'en')
      ? (_openBlock(), _createElementBlock("section", _hoisted_85, [
          _createElementVNode("div", _hoisted_86, [
            _cache[85] || (_cache[85] = _createElementVNode("span", { class: "text-action" }, "The Status of", -1)),
            _cache[86] || (_cache[86] = _createElementVNode("span", { class: "text-name" }, "Your", -1)),
            _cache[87] || (_cache[87] = _createElementVNode("span", { class: "text-idea" }, "Idea", -1)),
            _createElementVNode("span", _hoisted_87, [
              _cache[83] || (_cache[83] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[84] || (_cache[84] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[88] || (_cache[88] = _createElementVNode("span", { class: "text-action" }, "has been changed from", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[89] || (_cache[89] = _createElementVNode("span", { class: "text-action" }, "to", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5)
          ]),
          _createElementVNode("div", _hoisted_88, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 5 && _ctx.currentLanguage == 'zh')
      ? (_openBlock(), _createElementBlock("section", _hoisted_89, [
          _createElementVNode("div", _hoisted_90, [
            _cache[92] || (_cache[92] = _createElementVNode("span", { class: "text-name" }, "你", -1)),
            _cache[93] || (_cache[93] = _createElementVNode("span", { class: "text-action" }, "的", -1)),
            _cache[94] || (_cache[94] = _createElementVNode("span", { class: "text-idea" }, "想法", -1)),
            _createElementVNode("span", _hoisted_91, [
              _cache[90] || (_cache[90] = _createElementVNode("span", { class: "grey-text" }, "\"", -1)),
              _createTextVNode(_toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
            ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
            : _ctx.updatesItems.Update.IdeaTitle), 1),
              _cache[91] || (_cache[91] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
            ]),
            _cache[95] || (_cache[95] = _createElementVNode("span", { class: "text-action" }, "的状态已经从", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[96] || (_cache[96] = _createElementVNode("span", { class: "text-action" }, "变为", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5)
          ]),
          _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.updatesItems.Update.Type == 5 && _ctx.currentLanguage == 'jp')
      ? (_openBlock(), _createElementBlock("section", _hoisted_93, [
          _createElementVNode("div", _hoisted_94, [
            _cache[97] || (_cache[97] = _createElementVNode("span", { class: "text-action" }, "あなたのアイデア", -1)),
            _createElementVNode("span", _hoisted_95, "「" + _toDisplayString(_ctx.updatesItems.Update.IdeaTitle.length > 10
          ? _ctx.updatesItems.Update.IdeaTitle.slice(0, 10) + "..."
          : _ctx.updatesItems.Update.IdeaTitle) + "」", 1),
            _cache[98] || (_cache[98] = _createElementVNode("span", { class: "text-action" }, "のステータスが", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[0])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[0]))), 5),
            _cache[99] || (_cache[99] = _createElementVNode("span", { class: "text-action" }, "から", -1)),
            _createElementVNode("span", {
              class: "text-name",
              style: _normalizeStyle({
          color: _ctx.getStatusColor(parseInt(_ctx.updatesItems.Update.Content[1])),
        })
            }, _toDisplayString(_ctx.getStatusText(parseInt(_ctx.updatesItems.Update.Content[1]))), 5),
            _cache[100] || (_cache[100] = _createElementVNode("span", { class: "text-action" }, "に変更されました", -1))
          ]),
          _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.formatTimeAgo(_ctx.updatesItems.Update.CreatedOn)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}