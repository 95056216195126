<template>
  <el-collapse v-model="activeNames" id="pc-filter">
    <el-collapse-item name="Operation" :title="$t('operation')">
      <el-checkbox-group class="custom-checkbox-group" v-model="checkedOperations" @change="handleOperationChange">
        <el-checkbox v-for="operationOption in operationOptions" :key="operationOption.OperationName"
          :label="operationOption.OperationName">
          <span>{{ operationOption.OperationName }}</span>
          <div class="checkbox-right" :style="{
            color: operationOption.isChecked ? '#f36633' : '#868693',
          }">
            {{ operationOption.OperationCount
            }}<img :src="operationOption.isChecked
              ? operationOption.checkediconPath
              : operationOption.iconPath
              " class="icon" />
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item>
    <el-collapse-item :title="$t('status')" name="Status">
      <el-checkbox-group class="custom-checkbox-group" v-model="checkedStatus" @change="handleStatusChange">
        <el-checkbox v-for="(statusOption, statusoption_index) in statusOptions" :key="statusoption_index"
          :label="statusOption.StatusCode" v-model="statusOption.isChecked" :checked="statusOption.isChecked">
          <span>{{ statusOption.StatusName }}</span>
          <div class="checkbox-right" :style="{
            color: statusOption.isChecked
              ? getStatusColor(statusOption.StatusCode)
              : '#868693',
          }">
            {{ statusOption.StatusCount }}
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 8 9" fill="none">
              <circle cx="4" cy="4.5" r="4" :fill="getStatusColor(statusOption.StatusCode)" />
            </svg>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item>
    <!-- <el-collapse-item :title="$t('owner')" name="Owner">
      <el-checkbox-group class="custom-checkbox-group" v-model="checkedOwner" @change="handleOwnerChange">
        <el-checkbox v-for="ownerOption in ownerOptions" :key="ownerOption.UserId" :label="ownerOption.UserId"
          v-model="ownerOption.isChecked" :checked="ownerOption.isChecked">
          <span>{{ ownerOption.UserName }}</span>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item> -->
    <el-collapse-item :title="$t('category')" name="Category">
      <el-checkbox-group class="custom-checkbox-group" v-model="checkedCategory" @change="handleCategoryChange">
        <el-checkbox v-for="categoryOption in categoryOptions" :key="categoryOption.Category.CategoryId"
          :label="categoryOption.Category.CategoryId" v-model="categoryOption.Category.isChecked"
          :checked="categoryOption.Category.isChecked">
          <span>{{ categoryOption.Category.CategoryName }}</span>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item>
    <el-collapse-item :title="$t('tag')" name="Tag">
      <el-select-v2 v-model="checkedTag" :options="tagOptions" placeholder="Please select" size="large"
        style="width:80%; vertical-align: middle" filterable multiple clearable remote :remote-method="GetTagList"
        @change="handleTagChange" />
    </el-collapse-item>
  </el-collapse>
  <div id="mobile-filter">
    <el-row gutter="10">
      <el-col :span="8">
        <div class="filter-label">{{ $t('status') }}</div>
      </el-col>
      <el-col :span="8">
        <div class="filter-label">{{ $t('category') }}</div>
      </el-col>
      <el-col :span="8">
        <div class="filter-label">{{ $t('tag') }}</div>
      </el-col>
    </el-row>
    <el-row gutter="10">
      <el-col :span="8">
        <el-select v-model="checkedStatus" @change="handleStatusChange" placeholder="Status" multiple>
          <el-option v-for="status in statusOptions" :key="status.StatusCode" :label="status.StatusName"
            :value="status.StatusCode"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="checkedCategory" @change="handleCategoryChange" placeholder="Category" multiple>
          <el-option v-for="category in categoryOptions" :key="category.Category.CategoryId"
            :label="category.Category.CategoryName" :value="category.Category.CategoryId"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="checkedTag" @change="handleTagChange" placeholder="Tag" multiple clearable>
          <el-option v-for="tag in tagOptions" :key="tag.value" :label="tag.label" :value="tag.value"></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.custom-checkbox .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.collapseItem-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.icon {
  width: 16px;
  height: 16px;
}

:deep(.el-collapse-item__wrap) {
  border-bottom: none;
}

:deep(.el-collapse-item__header) {
  border-bottom: none;
}

.custom-checkbox-group {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.el-checkbox {
  display: flex;
  width: 90%;
  align-items: center;
  --el-checkbox-checked-text-color: #484851;
}

:deep(.el-checkbox__label) {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  flex-grow: 1;
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.checkbox-right {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #868693;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

:deep(.el-collapse-item__arrow) {
  margin-left: 12px;
}

:deep(.el-collapse-item__header.is-active .el-collapse-item__arrow) {
  transform: rotate(180deg);
}

:deep(.el-collapse-item__header) {
  color: #3c3c43;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  /* 150% */
}

:deep(.el-collapse-item__arrow) {
  /* 隐藏默认箭头 */
  visibility: hidden;
  position: relative;
}

:deep(.el-collapse-item__arrow::after) {
  /* 显示自定义图标 */
  content: url("@/assets/icons/caret-down.svg");
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}

#mobile-filter {
  display: none;
}

#pc-filter {
  display: block;
}

@media only screen and (max-width: 1024px) {
  #pc-filter {
    display: none;
  }

  #mobile-filter {
    display: block;
  }

  .filter-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
}
</style>

<script lang="ts">
import { ref, reactive, onMounted, inject, defineComponent } from "vue";
import { AxiosInstance } from "axios";
import { getUserInfo } from "@/state/UserInfo";
import { getStatusColor } from "@/utils/getStatusColor";

export default defineComponent({
  name: "IdeaFilter",
  props: {
    initialStatusCode: {
      type: Number,
      default: NaN,
    },
  },
  setup(props, context) {
    const activeNames = ref(["Operation", "Status", "Owner", "Category", "Tag"]);

    interface StatusOption {
      StatusCode: number;
      StatusCount: number;
      StatusName: string;
      isChecked: boolean;
    }

    interface OwnerOption {
      UserId: number;
      UserName: string;
      Avatar: string;
      isChecked: boolean;
    }

    interface OperationOption {
      OperationName: string;
      OperationCount: number;
      isChecked: boolean;
      iconPath: string;
      checkediconPath: string;
    }
    interface Option {
      value: number;
      label: string;
    }
    let statusOptions = reactive<StatusOption[]>([]);
    let ownerOptions = reactive<OwnerOption[]>([]);
    let operationOptions = reactive<OperationOption[]>([]);
    let categoryOptions = reactive<any[]>([]);
    const tagOptions = ref<Option[]>([])
    const checkedOperations = ref<string[]>([]);
    const checkedStatus = ref<number[]>([]);
    const checkedOwner = ref<number[]>([]);
    const checkedCategory = ref<number[]>([]);
    const checkedTag = ref<number[]>([]);
    const axiosInstance = inject("axiosInstance") as AxiosInstance;

    onMounted(() => {
      getIdeaStatusCount();
      // getIdeaOwnerList();
      getIdeaOperationCount();
      getIdeaCategory();
      GetTagList();
    });
    const GetTagList = (keywords?: string) => {
      const params = keywords ? { keywords } : {};
      axiosInstance.get("api/Tag/GetTagList", { params }).then((res) => {
        tagOptions.value = res.data.Data.map((option: { Tag: { TagId: any; TagName: any; }; }) => ({
          value: option.Tag.TagId,
          label: option.Tag.TagName
        }));
      });
    };


    const getIdeaCategory = () => {
      axiosInstance
        .request({
          url: "api/Category/GetCategoryList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const CategoryOptions = res.data.Data;
          CategoryOptions.forEach((option: OperationOption) => {
            option.isChecked = false;
          });
          Object.assign(categoryOptions, CategoryOptions);
          console.log("CategoryOptions", CategoryOptions);
        });
    };

    /**
     * Retrieves the count of ideas for each status from the server and assigns the resulting list to the `statusOptions` variable.
     * Each option in the list is also assigned a `isChecked` property with a value of `true`.
     * @function
     * @name getIdeaStatusCount
     * @returns {void}
     */
    const getIdeaStatusCount = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaStatusCount",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const StatusOptions = res.data.Data;
          StatusOptions.forEach((option: StatusOption) => {
            option.isChecked = false;
          });
          if (!Number.isNaN(props.initialStatusCode)) {
            StatusOptions.forEach((option: StatusOption) => {
              if (option.StatusCode == props.initialStatusCode) {
                option.isChecked = true;
              } else {
                option.isChecked = false;
              }
            });
            checkedStatus.value.push(props.initialStatusCode);
          }
          Object.assign(statusOptions, StatusOptions);
        });
    };

    /**
     * Retrieves the list of idea owners from the server and assigns the resulting list to the `ownerOptions` variable.
     * Each option in the list is also assigned a `isChecked` property with a value of `true`.
     * @function
     * @name getIdeaOwnerList
     * @returns {void}
     */
    const getIdeaOwnerList = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaOwnerList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const OwnerOptions = res.data.Data;
          console.log(res);
          OwnerOptions.forEach((option: OwnerOption) => {
            option.isChecked = false;
          });
          Object.assign(ownerOptions, OwnerOptions);
        });
    };

    const getIdeaOperationCount = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaOperationCount",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const OperationOptions = res.data.Data;
          OperationOptions.forEach((option: OperationOption) => {
            option.isChecked = false;
          });
          OperationOptions[0].iconPath = require("@/assets/icons/star.svg");
          OperationOptions[0].checkediconPath = require("@/assets/icons/star-fill.svg");
          OperationOptions[1].iconPath = require("@/assets/icons/zan.svg");
          OperationOptions[1].checkediconPath = require("@/assets/icons/zan-fill.svg");
          OperationOptions[2].iconPath = require("@/assets/icons/comment.svg");
          OperationOptions[2].checkediconPath = require("@/assets/icons/comment-fill.svg");
          Object.assign(operationOptions, OperationOptions);
        });
    };

    /**
     * Handles the change event for the status filter checkboxes.
     * Loops through each option in the `statusOptions` array and sets its `isChecked` property to `true` if its `StatusName` property is included in the `checkedStatus` array.
     * Calls the `checkedIdeaListChange` function to update the list of checked ideas.
     * @function
     * @name handleStatusChange
     * @returns {void}
     */
    const handleStatusChange = () => {
      statusOptions.forEach((option) => {
        option.isChecked = checkedStatus.value.includes(option.StatusCode);
      });
      emitValues();
    };

    const handleTagChange = () => {
      emitValues();
    };
    const handleOperationChange = () => {
      operationOptions.forEach((option) => {
        option.isChecked = checkedOperations.value.includes(
          option.OperationName
        );
      });
      emitValues();
    };

    /**
     * Handles the change event for the owner filter checkboxes.
     * Loops through each option in the `ownerOptions` array and sets its `isChecked` property to `true` if its `UserName` property is included in the `checkedOwner` array.
     * Calls the `checkedIdeaListChange` function to update the list of checked ideas.
     * @function
     * @name handleOwnerChange
     * @returns {void}
     */
    const handleOwnerChange = () => {
      ownerOptions.forEach((option) => {
        option.isChecked = checkedOwner.value.includes(option.UserId);
      });
      emitValues();
    };

    const handleCategoryChange = () => {
      categoryOptions.forEach((option) => {
        option.isChecked = checkedCategory.value.includes(option.Category.CategoryId);
      });
      emitValues();
    };

    const emitValues = () => {
      context.emit("filterUpdate", {
        checkedOperations: checkedOperations.value,
        checkedStatus: checkedStatus.value,
        // checkedOwner: checkedOwner.value,
        checkedCategory: checkedCategory.value,
        checkedTag: checkedTag.value
      });
    };

    return {
      activeNames,
      operationOptions,
      checkedOperations,
      statusOptions,
      checkedStatus,
      ownerOptions,
      checkedOwner,
      categoryOptions,
      checkedCategory,
      checkedTag,
      tagOptions,
      handleCategoryChange,
      handleOperationChange,
      handleStatusChange,
      handleOwnerChange,
      getStatusColor,
      GetTagList,
      handleTagChange
    };
  },
});
</script>
