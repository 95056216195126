import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  fill: "none"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = { id: "mobile-filter" }
const _hoisted_5 = { class: "filter-label" }
const _hoisted_6 = { class: "filter-label" }
const _hoisted_7 = { class: "filter-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_collapse, {
      modelValue: _ctx.activeNames,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.activeNames) = $event)),
      id: "pc-filter"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_collapse_item, {
          name: "Operation",
          title: _ctx.$t('operation')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox_group, {
              class: "custom-checkbox-group",
              modelValue: _ctx.checkedOperations,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedOperations) = $event)),
              onChange: _ctx.handleOperationChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operationOptions, (operationOption) => {
                  return (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: operationOption.OperationName,
                    label: operationOption.OperationName
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(operationOption.OperationName), 1),
                      _createElementVNode("div", {
                        class: "checkbox-right",
                        style: _normalizeStyle({
            color: operationOption.isChecked ? '#f36633' : '#868693',
          })
                      }, [
                        _createTextVNode(_toDisplayString(operationOption.OperationCount), 1),
                        _createElementVNode("img", {
                          src: operationOption.isChecked
              ? operationOption.checkediconPath
              : operationOption.iconPath
              ,
                          class: "icon"
                        }, null, 8, _hoisted_1)
                      ], 4)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_el_collapse_item, {
          title: _ctx.$t('status'),
          name: "Status"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox_group, {
              class: "custom-checkbox-group",
              modelValue: _ctx.checkedStatus,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedStatus) = $event)),
              onChange: _ctx.handleStatusChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (statusOption, statusoption_index) => {
                  return (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: statusoption_index,
                    label: statusOption.StatusCode,
                    modelValue: statusOption.isChecked,
                    "onUpdate:modelValue": ($event: any) => ((statusOption.isChecked) = $event),
                    checked: statusOption.isChecked
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(statusOption.StatusName), 1),
                      _createElementVNode("div", {
                        class: "checkbox-right",
                        style: _normalizeStyle({
            color: statusOption.isChecked
              ? _ctx.getStatusColor(statusOption.StatusCode)
              : '#868693',
          })
                      }, [
                        _createTextVNode(_toDisplayString(statusOption.StatusCount) + " ", 1),
                        (_openBlock(), _createElementBlock("svg", _hoisted_2, [
                          _createElementVNode("circle", {
                            cx: "4",
                            cy: "4.5",
                            r: "4",
                            fill: _ctx.getStatusColor(statusOption.StatusCode)
                          }, null, 8, _hoisted_3)
                        ]))
                      ], 4)
                    ]),
                    _: 2
                  }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_el_collapse_item, {
          title: _ctx.$t('category'),
          name: "Category"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox_group, {
              class: "custom-checkbox-group",
              modelValue: _ctx.checkedCategory,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.checkedCategory) = $event)),
              onChange: _ctx.handleCategoryChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOptions, (categoryOption) => {
                  return (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: categoryOption.Category.CategoryId,
                    label: categoryOption.Category.CategoryId,
                    modelValue: categoryOption.Category.isChecked,
                    "onUpdate:modelValue": ($event: any) => ((categoryOption.Category.isChecked) = $event),
                    checked: categoryOption.Category.isChecked
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(categoryOption.Category.CategoryName), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_el_collapse_item, {
          title: _ctx.$t('tag'),
          name: "Tag"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select_v2, {
              modelValue: _ctx.checkedTag,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkedTag) = $event)),
              options: _ctx.tagOptions,
              placeholder: "Please select",
              size: "large",
              style: {"width":"80%","vertical-align":"middle"},
              filterable: "",
              multiple: "",
              clearable: "",
              remote: "",
              "remote-method": _ctx.GetTagList,
              onChange: _ctx.handleTagChange
            }, null, 8, ["modelValue", "options", "remote-method", "onChange"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_row, { gutter: "10" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('status')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('category')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('tag')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, { gutter: "10" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.checkedStatus,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.checkedStatus) = $event)),
                onChange: _ctx.handleStatusChange,
                placeholder: "Status",
                multiple: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (status) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: status.StatusCode,
                      label: status.StatusName,
                      value: status.StatusCode
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.checkedCategory,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.checkedCategory) = $event)),
                onChange: _ctx.handleCategoryChange,
                placeholder: "Category",
                multiple: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOptions, (category) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: category.Category.CategoryId,
                      label: category.Category.CategoryName,
                      value: category.Category.CategoryId
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.checkedTag,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.checkedTag) = $event)),
                onChange: _ctx.handleTagChange,
                placeholder: "Tag",
                multiple: "",
                clearable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagOptions, (tag) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: tag.value,
                      label: tag.label,
                      value: tag.value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}