interface GraphUserInfo {
  "@odata.context": string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string | null;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string | null;
  surname: string;
  userPrincipalName: string;
  UserId: number;
  avatar: string;
  authority: number;
  pendingAdmit: Boolean;
  userName: string;
  userId: number;
}

interface TokenResult {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  expire_date: number;
  scope: string;
  token_type: string;
}

export interface UserInfo {
  wecom_userid: string;
  graphUserInfo?: GraphUserInfo;
  tokenResult?: TokenResult;
}

// Get userInfo directly from sessionStorage
export function getUserInfo() {
  const storedUserInfo = sessionStorage.getItem("userInfo");
  return storedUserInfo ? (JSON.parse(storedUserInfo) as UserInfo) : null;
}

// Save userInfo directly to sessionStorage
export function saveUserInfo(userInfo: UserInfo | null): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      if (userInfo) {
        if (userInfo.tokenResult && userInfo.tokenResult.expires_in) {
          const expiresInMilliseconds = userInfo.tokenResult.expires_in * 1000;
          const expirationDate = new Date().getTime() + expiresInMilliseconds;
          userInfo.tokenResult.expire_date = expirationDate;
        }
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      } else {
        sessionStorage.removeItem("userInfo");
      }
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}
